

/*=================================================================
  Navigation
==================================================================*/
.navigation {
  background: rgba(0, 0, 0, 0.7);
  -webkit-transition: .3s all;
  -o-transition: .3s all;
  transition: .3s all;
  .navbar-nav > li {
    &.active {
      a {
        color:$primary-color;
      }
    }
     > a {
      color: #fff;
      font-size: 12px;
      line-height: 26px;
      padding: 20px 15px;
      text-transform: uppercase;
      letter-spacing: 2px;
      -webkit-transition: .2s ease-in-out 0s;
      -moz-transition: .2s ease-in-out 0s;
      -o-transition: .2s ease-in-out 0s;
      transition: .2s ease-in-out 0s;
      &:hover, &:active, &:focus{
        background: none;
        color: $primary-color;
      }
    }
  }
}


.sticky-header {
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.06);
  background: $light!important;
  .navbar-nav >li {
    >a {
      color: #000;
    }
  }
}

.navbar-brand {
  height: auto;
  padding: 8px 0;
}

.navbar-brand h1 {
  margin: 0;
}


