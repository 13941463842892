/*=================================================================
  Latest Posts
==================================================================*/

.blog {
  padding-bottom: 110px;
  padding-top: 70px;
  background: #F6F6F6;
}

.post-item {
  background: $light;
  margin-bottom: 20px;
  .content {
    padding:20px;
    h3 {
      a {
        color: #000;
      }
    }
    p {
      color: #757575;
      font-size: 14px;
      margin: 10px 0;
    }
    .btn-main {
      padding:12px 25px;
      margin:15px 0;
    }
  }
}
