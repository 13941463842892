
/*=================================================================
  Item counter section
==================================================================*/

.counter {
  padding: 100px 0;
  .counter-title {
    margin-bottom: 50px;
    h2 {
      color: $black;
      margin-bottom: 20px;
      font-size: 42px;
      font-weight: 600;
      line-height: 35px;
    }
    p {
      color: #555;
      font-size: 16px;
    }
  }
}

.counters-item {
  padding-bottom: 30px;
  color:#444;
  position: relative;
  &.kill-border:before {
    background: none;
  }
  &:before {
    background: #e5e5e5;
    content: "";
    margin-top: -27.5px;
    height: 55px;
    width: 1px;
    position: absolute;
    right: -15px;
    top: 50%;
  }
  > div {
    margin: 6px 0;
  }
  span {
    display: inline;
    font-size: 35px;
    line-height: 50px;
  }
  i {
    font-size:40px;
    display: inline-block;
    color:$primary-color;
  }
  h3 {
    font-size: 17px;
    margin: 0;
  }
}

