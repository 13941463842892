
/*=================================================================
  Portfolio section
==================================================================*/

#portfolio-items-wrapper .mix{
    display: none;
}
.portfolio {
  padding:100px 0;
}

.portfolio-filter {
  margin-bottom: 40px;
  text-align: center;
  ul {
    li {
      cursor: pointer;
      color: #444;
      display: inline-block;
      font-size: 13px;
      padding:8px 20px;
      text-transform: uppercase;
      font-weight: bold;
      &.active {
        background: $primary-color;
        color:$light;
        border-radius: 50px;
      }
    }
  }
}

.portfolio-block {
  position: relative;
  margin-bottom: 30px;
  &:before {
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background: rgba(0, 0, 0, 0.55);
    opacity: 0;
    top:0;
    right:0;
    bottom:0;
    left:0;
    position:absolute;
    content: '';
  }
  &:hover:before {
    opacity: 1;
  }
  &:hover .caption h4 {
    top:45%;
  }
  &:hover .caption {
    opacity: 1;
    top: 55%;
  }
  .caption {
    position: absolute;
    top:50%;
    left:50%;
    opacity: 0;
    transform: translate(-50%, -50%);
    z-index: 9;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    text-align: center;
    h4 {
      font-size: 16px;  
      a {
        color: $light;
      }
    }
    .search-icon {
      background: $primary-color;
      color:$light;
      display: inline-block;
      padding: 4px 13px;
      font-size: 25px;
      border-radius: 30px;
    }
  }
}


